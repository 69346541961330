<template>
  <div class="annex">
    <UploadList
      :disabled="disabled"
      :value="value"
      :fields="{ name: 'fileName', path: 'filePath', type: 'type' }"
      tableHeight="calc(100vh - 347px)"
      type="BASIC"
    ></UploadList>
    <!-- v-model="form.agreementFileList" -->
    <!-- 移除v-model绑定，附件数组数据还没返回便传参过来 -->
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'

export default {
  components: {
    UploadList: () => import('@/components/upload/List.vue'),
  },
  props: {
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
    value: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  data() {
    return {
      disabled: true,
      data1: [],
      data2: [],
    }
  },
  inject: ['form', 'refresh'],
  computed: {},
  watch: {
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (options) {
        this.disabled = true
        if (options.ProjectDisable == 5) {
          this.disabled = true
          return
        }
        if (options.id) {
          if (this.permission(['PROJECTFILEEDIT'])) {
            if (
              options.status == 0 &&
              options.operatingButton.some(r => {
                return r == 'EDIT'
              })
            ) {
              this.disabled = false
            }
          }
          if (this.permission(['AGREEMENT_FILE_EDIT'])) {
            this.disabled = false
          }
        } else {
          this.disabled = false
        }
      },
    },
  },
  created() {},
  methods: {},
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
.annex {
  display: flex;
  justify-content: space-between;
  /deep/.el-card {
    width: 50%;
    margin: 0 10px;
    .el-card__header {
      padding: 10px 15px;
      font-size: 16px;
      font-weight: bold;
    }
    .el-table {
      height: calc(100vh - 347px);
    }
  }
}
</style>
